import React, { useEffect } from 'react';
import _, { bind } from 'lodash';
import {graphql} from 'gatsby';

import components, {Layout} from '../components/index';
import FormField from './../components/FormField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// add aws-cli into aplication and import models
import Amplify, { API, graphqlOperation, Auth, DataStore, Predicates }  from "aws-amplify";
import awsConfig from "../aws-exports";
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react'
import { withAuthenticator } from "aws-amplify-react"
import { Post, User, Pet, Preference } from '../models';

import * as Queries from '../graphql/queries'
import { 
  createUser as CreateUser, 
  createPet as CreatePet,
  createPreference as CreatePreference, 
  updateUser as UpdateUser 
} from '../graphql/mutations'

Amplify.configure(awsConfig); 

import { v4 as uuidv4 } from 'uuid'

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

Auth.currentAuthenticatedUser()
  .then(data => {
    console.log(data)
    
  })
  .catch(err => console.log(err));

const myListPets=`
  query {
    listPets {
      items {
        id
        name_pet
        name_vet
        color_pet
      }
    }
  }
`

class Advanced extends React.Component {
  constructor(props){
    super(props)
    this.handleItem = this.handleItem.bind(this)
    this.handleAuto = this.handleAuto.bind(this)
    this.submit     = this.submit.bind(this)
    this.setLocal   = this.setLocal.bind(this)
    this.getLocal   = this.getLocal.bind(this)
    this.transferData = this.transferData.bind(this)

    this.handleClickAlert = this.handleClickAlert.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);

    // this.handleClickDialog = this.handleClickDialog.bind(this);
    this.handleCloseQDialog = this.handleCloseQDialog.bind(this);

    this.API        = this.API
    this.state= {
      showD: false,
      alertShow: false,
      myForm: false,
      alertDialog: true,
      alertShowMessage: "Profile saved!",
      valueItems : [],
      newSavedUser : {},
      newSavedPet: {},
      vetsInfo: []
    }
  }

      transferData = async (event) => {
        event.preventDefault();
        let userID = await this.getLocal('userId', false, false);
        console.log('CLIENT_ID = ', userID);

        const updateUser = {
          id :          userID,
          name_user:    this.state.valueItems.nameOwner || 'test',
          mobile_user:  this.state.valueItems.mobile    || '+1 800 800 800'
        }
        let savedUser = await API.graphql(graphqlOperation(UpdateUser, { input: updateUser }  ))
        console.log('savedUser', savedUser);
        (savedUser) ? await this.setLocal('newSavedUser', savedUser, true) : await this.setLocal('newSavedUser', 'savedUser', false);
        await this.setLocal('userId', savedUser.id, false);
        await this.setLocal('userEmail', savedUser.email, false);
        console.log('new record from DataStore/ SavedUser.data = ', savedUser);
        
        let newPet = {
          name_pet:     this.state.valueItems.namePet   || '',
          age_pet:      this.state.valueItems.agePet    || '',
          breed_pet:    this.state.valueItems.breedPet  || '', 
          color_pet:    this.state.valueItems.colorPet  || '',
          weight_pet:   this.state.valueItems.weightPet || '',
          name_vet:     this.state.valueItems.search    || "Dr. Aibolit",
          // User: savedUser
          petUserId: savedUser.data.updateUser.id
          // savedUser
        }
        console.log('newPet', newPet);
        let savedPet = await API.graphql(graphqlOperation(CreatePet, { input: newPet }  ))
        console.log('savedPet.data.createPet.id:', savedPet.data.createPet.id);
        await this.setLocal('newSavedPet', newPet, true);
        await this.setLocal('state', this.state, true);
        
        console.log('newPreference ==>:');
        let newPreference = {
          rec_dosing_sms:   this.state.valueItems.dosingSMS   || false,
          rec_dosing_email: this.state.valueItems.dosingEmail || false,
          rec_presc_sms:    this.state.valueItems.prescSMS    || false,
          rec_presc_email:  this.state.valueItems.prescEmail  || false,
          email_or_sms:     this.state.valueItems.emailOrSMS  || this.state.valueItems.mobile
        }
        console.log('newPreference:', newPreference);
        let savedPreference = await API.graphql(graphqlOperation(CreatePreference, { input: newPreference }  ))
        console.log('savedPreference.data:', savedPreference.data);
        updateUser.userPetId = savedPet.data.createPet.id
        updateUser.userPreferenceId = savedPreference.data.createPreference.id
        console.log('=>', updateUser);
        savedUser = await API.graphql(graphqlOperation(UpdateUser, { input: updateUser }  ))
        console.log('savedUser.data:', savedUser.data);
        this.setState( prevState => {
          return {
            ...prevState,
            newSavedUser: savedUser,
            alertShow: true,
            alertDialog: true
          
        }});
        
      }

      handleClickAlert = () => {
        this.setState({alertShow: true})
      };
      handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
      this.setState({alertShow: false})
      //  window.location.assign(`/area/`)
      };

      // handleClickDialog = async (event) => {
      //   // this.setState({showD: true, myForm: true});
      // };
      handleCloseQDialog = (event) => {
        this.setState({alertDialog: true});
      };

      async setLocal(addr, data, param) {
        if (param) {
          localStorage.setItem(addr, JSON.stringify(data))
        } else {
          localStorage.setItem(addr, data)
        }
        return true
      }

      async getLocal(addr, param, setSt) {
        let fromStorage = await localStorage.getItem(addr) 
        if (fromStorage == null) return false
        let dataFromStorage = (param)? JSON.parse(fromStorage) : fromStorage
        console.log(dataFromStorage);
        
        (setSt) ? this.setState(dataFromStorage) : null
        return dataFromStorage
      }

      getInitialState() {
        return {};
      }

      submit = async (event) => {
        event.preventDefault();
        
        // this.setState(prevState => {
        //   return {
        //     ...prevState,
        //     alertDialog: false
        //   };
        // })
        await this.transferData(event);
      }

      handleAuto(event) {
        let newTarget = event.target.id.split('-')
        let newStateItems = this.state.valueItems
        let vetIndex = newTarget[2]
        newStateItems[`search`] = this.state.vetsInfo[vetIndex].name_vet;
        console.log('newStateItems[`search`]', newStateItems[`search`]);
        this.setState({valueItems: newStateItems})
      }

      handleItem(event) {
        console.log('!!!', event.target);
        let newStateItems = this.state.valueItems
        let ItemType = event.target.type;
        let newValue
        if ( ItemType == 'radio' ) {
          newStateItems[`radioEmail`] = "off";
          newStateItems[`radioSMS`] = "off";
          newStateItems[`radioBoth`] = "off";
          newStateItems[`${event.target.id}`] = "on";
        } if ( ItemType == 'checkbox' ) {
          newValue = event.target.checked
          newStateItems[`${event.target.name}`] = newValue;
        } else {
          newValue = event.target.value
          newStateItems[`${event.target.name}`] = newValue;
        }
        
        this.setState({valueItems: newStateItems})
        console.log('this.state = ', this.state.valueItems);
      }

      UNSAFE_componentWillMount = async () => {
        await this.getLocal('state', true, true)
        await Auth.currentAuthenticatedUser()
          .then(async (user) => {
            // return Auth.changePassword(user, 'oldPassword', 'newPassword');
            // console.log('user.name:', user.username);
            let data = await API.graphql(graphqlOperation(Queries.getUser, {id: user.username}))
            await this.setLocal('userId', user.username, false);
          
            console.log('GetUser in data = ', data.data.getUser)
            
            if (data.data.getUser == null || data.data.getUser == undefined) {
              
              let newUser = {
                id :          user.username,
                email:        user.attributes.email     || 'l.ko@gmail.com',
              }
              let savedPet = await API.graphql(graphqlOperation(CreateUser, { input: newUser }  ))
              // console.log("savedPet.data: ,savedPet.data");
              
            } else {
              console.log('Preference', data.data.getUser.Preference);
              let newStateItems = this.state.valueItems;
              newStateItems[`email`]      = data.data.getUser.email || "";
              newStateItems[`nameOwner`]  = data.data.getUser.name_user || "";
              newStateItems[`mobile`]     = data.data.getUser.mobile_user || "";
              if (data.data.getUser.Pet) {
                newStateItems[`namePet`]    = data.data.getUser.Pet.name_pet || "";
                newStateItems[`agePet`]     = data.data.getUser.Pet.age_pet || "";
                newStateItems[`breedPet`]   = data.data.getUser.Pet.breed_pet || "";
                newStateItems[`colorPet`]   = data.data.getUser.Pet.color_pet || "";
                newStateItems[`weightPet`]  = data.data.getUser.Pet.weight_pet || "";
                newStateItems[`search`]     = data.data.getUser.Pet.name_vet || "";
              }
              if (data.data.getUser.Preference) {
                newStateItems['dosingSMS']  = (data.data.getUser.Preference.rec_dosing_sms) ? true : false; 
                newStateItems['dosingEmail']= (data.data.getUser.Preference.rec_dosing_email) ? true : false; 
                newStateItems['prescSMS']   = (data.data.getUser.Preference.rec_presc_sms) ? true : false; 
                newStateItems['prescEmail'] = (data.data.getUser.Preference.rec_presc_email) ? true : false;
                newStateItems['emailOrSMS'] = data.data.getUser.Preference.email_or_sms || data.data.getUser.email
              }
              console.log("newStateItems", newStateItems);
              console.log("this.state.valueItems", this.state);
              let vetsInfoFetched = await API.graphql(graphqlOperation(Queries.listVets));
              console.log('vetsInfoFetched', vetsInfoFetched.data.listVets.items);
              this.setState(prevState => {
                return {
                  ...prevState,
                  valueItems: newStateItems,
                  alertDialog: true,
                  vetsInfo: vetsInfoFetched.data.listVets.items
                }
              })
            }
          })
          .catch(err => console.log(err));
      }

      render() {
        let sectionsInfo = _.get(this.props, 'pageContext.frontmatter.sections', null)
      
        // console.log('tut props = ', sectionsInfo)
        let section = sectionsInfo[0];
        // console.log('section first = ', section);
        return (
          <AmplifyAuthenticator usernameAlias="email">
            
            <AmplifySignOut />
            <Snackbar 
              anchorOrigin = {{ 
                    vertical: 'bottom',
                    horizontal: 'center'
                  }} 
              open = {this.state.alertShow} 
              autoHideDuration = {6000} 
              onClose = {this.handleCloseAlert}
            >
              <Alert 
                onClose = {this.handleCloseAlert}
                severity = "success"
                action = {
                  <IconButton
                    aria-label = "close"
                    color = "inherit"
                    size = "large"
                    onClick = {() => {
                      this.setState({alertShow: false})
                    }}
                  >
                    <DoneAllIcon fontSize = "inherit" />
                  </IconButton>
                }
              >
                {this.state.alertShowMessage}
              </Alert>
            </Snackbar>
            
            <AmplifySignIn slot="sign-in" usernameAlias="email" />
            <Layout {...this.props}>
              {(!_.get(this.props, 'pageContext.frontmatter.hide_title', null)) && (
                <header className="page-header inner-sm outer">
                  <h1 className="page-title line-top">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                </header>
              )}
              <div className="block-content inner">
                <section id={_.get(section, 'section_id', null)} className="block block-form outer">
                  
                  <div className="inner">
                    {(_.get(section, 'title', null) || _.get(section, 'subtitle', null)) && (
                      <div className="block-header inner-sm">
                        {_.get(section, 'title', null) && (
                        <h2 className="block-title line-top">{_.get(section, 'title', null)}</h2>
                        )}
                        {_.get(section, 'subtitle', null) && (
                        <p className="block-subtitle">{htmlToReact(_.get(section, 'subtitle', null))}</p>
                        )}
                      </div>
                    )}
                  </div>
                
                  <div className="block-content inner-sm">
                    
                    <form name={_.get(section, 'form_id', null)} id={_.get(section, 'form_id', null)} onSubmit={this.submit} >
                        <div className="grid grid-col-2">
                          <div key="1" className="grid-item grid-margin-bottom">
                            {_.map(_.get(section, 'form_fields', null), (field, field_idx) => {
                              if (field.position == 1) return <FormField 
                                                                onChange={this.handleItem} 
                                                                key={field_idx} 
                                                                {...this.props} 
                                                                field={field}
                                                                value={this.state.valueItems[field.name]}
                                                              />
                            })}
                          </div>
                          <div key="2" className="grid-item grid-margin-bottom">
                            {_.map(_.get(section, 'form_fields', null), (field, field_idx) => {
                              // console.log("field in", field.name, this.state.valueItems[field.name]);
                              if (field.position == 2) return <FormField 
                                                                onChange={this.handleItem} 
                                                                key={field_idx} 
                                                                {...this.props} 
                                                                field={field}
                                                                value={this.state.valueItems[field.name]}
                                                              />
                            })}
                            <Autocomplete
                              id="search"
                              // onChange={this.handleAuto}  
                              onChange={this.handleAuto}
                              
                                  // onHighlightChange={this.handleAuto}
                                  // onClose={this.handleAuto}
                              value={this.state.valueItems.search || ""} 
                              name="search" 
                              // defaultValue={this.state.valueItems.search || ""}
                              options={this.state.vetsInfo.map((option) => option.name_vet + " (" + option.city_vet + ")" )}
                              renderInput={(params) => (
                                <TextField 
                                  {...params} 
                                  label="Vet's name" 
                                  onChange={this.handleItem}  
                                  // onInputChange={this.handleAuto}
                                  // onHighlightChange={this.handleAuto}
                                  // onClose={this.handleAuto}
                                  name="search-2" 
                                  margin="normal" 
                                  variant="outlined" 
                                />
                              )}
                            />
                          </div>
                          <div key="3" className="grid-item">
                            {_.map(_.get(section, 'form_fields', null), (field, field_idx) => {
                              if (field.position == 3) return <FormField 
                                                                onChange={this.handleItem} 
                                                                key={field_idx} 
                                                                {...this.props} 
                                                                field={field}
                                                                value={this.state.valueItems[field.name]}
                                                              />
                            })}
                          </div>
                          <div key="34" className="grid-item">
                            {_.map(_.get(section, 'form_fields', null), (field, field_idx) => {
                              if (field.position == 4) return <FormField
                                                                onChange={this.handleItem}
                                                                key={field_idx} {...this.props}
                                                                field={field}
                                                                {...this.props} 
                                                                value={this.state.valueItems[field.name]}
                                                              />
                            })}
                          </div>
                        </div>
                        {_.map(_.get(section, 'form_fields', null), (field, field_idx) => {
                                if (field.position == 0) return <FormField onChange={this.handleItem} key={field_idx} {...this.props} field={field} />
                            })}
                        <div className="form-submit">
                          <button type="submit" className="button">{_.get(section, 'submit_label', null)}</button>
                        </div>
                    </form>
                    
                  </div> 
                </section>
                {/* <Snackbar 
                  anchorOrigin = {{ 
                        vertical: 'bottom',
                        horizontal: 'center'
                      }} 
                  open = {!this.state.alertDialog} 
                  autoHideDuration = {6000} 
                  onClose = {this.handleCloseQDialog}
                >
                  <Alert 
                    onClose = {this.handleCloseQDialog}
                    severity = "info"
                    action = {
                      <>
                        <IconButton
                          aria-label = "close"
                          color = "inherit"
                          size = "medium"
                          onClick = {() => {
                            this.setState({alertDialog: false})
                          }}
                        >
                          <CloseIcon fontSize = "inherit" />
                        </IconButton>
                        <IconButton
                          aria-label = "success"
                          color = "inherit"
                          size = "medium"
                          onClick = {async (event) => {
                            await this.transferData(event)
                          }}
                        >
                          <DoneIcon fontSize="inherit" />
                        </IconButton>
                      </>
                      
                    }
                  >
                    {this.state.alertShowMessage}
                  </Alert>
                </Snackbar> */}
              </div>
            </Layout>
          </AmplifyAuthenticator>
        );
    }
}

export default Advanced